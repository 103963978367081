
export default {
  name: 'CloseButton',
  props: {
    target: {
      type: [String, Number],
      description: 'Close button target element',
      required: true,
    },
    expanded: {
      type: Boolean,
      description: 'Whether button is expanded (aria-expanded attribute)',
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt)
    },
  },
}
