
import Navbar from '../components/layout/Navbar.vue'
import GettingStarted from '../components/content/GettingStarted.vue'
import DefaultFooter from '../components/layout/DefaultFooter.vue'

export default {
  components: {
    Navbar,
    GettingStarted,
    DefaultFooter,
  },

  computed: {
    showSeparated() {
      if (this.$route.path === '/') {
        return true
      } else {
        return false
      }
    },
  },
}
