
export default {
  name: 'GettingStarted',
  props: {
    separated: Boolean,
    title: {
      type: String,
      required: true,
    },
  },
}
