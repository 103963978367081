
import CloseButton from '../layout/CloseButton'
import ToggleButton from '../layout/ToggleButton'

export default {
  name: 'Navbar',
  components: {
    CloseButton,
    ToggleButton,
  },
  props: {
    isDarkMode: {
      type: Boolean,
      description: 'Whether to put the navbar in dark mode or light mode',
    },
    isWhite: {
      type: Boolean,
      description: 'Whether to use white or a darker tone',
    },
  },
  data() {
    return {
      toggled: false,
      background: () => {
        if (this.isDarkMode) {
          if (this.toggled) {
            return 'navbar-light bg-gradient-tertiary'
          } else {
            return 'navbar-dark bg-gradient-tertiary'
          }
        } else if (this.isWhite) {
          if (this.toggled) {
            return 'navbar-dark'
          } else {
            return 'navbar-light'
          }
        } else {
          if (this.toggled) {
            return 'navbar-dark bg-quaternary'
          }

          return 'navbar-light bg-quaternary'
        }
      },
    }
  },
  methods: {
    toggleMenu() {
      this.toggled = !this.toggled
    },
  },
}
